import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Fab, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useGlobalMagicDialog } from './Dialog/GlobalMagicDialogContext';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: (props: { isMobile: boolean }) => (props.isMobile ? 80 : 20),
    right: 20,
    zIndex: 1000,
  },
}));

const FloatingMagicButton: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles({ isMobile });
  const { openMagicDialog } = useGlobalMagicDialog();

  return (
    <Fab color='primary' className={classes.fab} onClick={() => openMagicDialog()} aria-label='AI Assistant'>
      <AutoFixHighIcon />
    </Fab>
  );
};

export default FloatingMagicButton;
