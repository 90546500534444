import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import { Box, CircularProgress, IconButton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import TooltipIcon from 'components/TooltipIcon';
import { saveAs } from 'file-saver';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import theme from 'theme';
import type { SiteDetails } from '../../../dataHandlers/ObservationSiteStore';
import { useCurrentUser, useFarmStore, useUserStore } from '../../../dataHandlers/RootStore';
import type { IMinMax, TransformedData } from '../../../dataHandlers/SensorTransformer';
import { customerVisibleDeviceId } from '../../../dataHandlers/utils/formatters';
import { hasCalibration } from '../../../dataHandlers/utils/typeGuards';
import formatWithFallback from '../../../utils/formatWithFallback';
import getErrorMessageIntl from '../../../utils/getErrorMessageIntl';
import useTraceUpdate from '../../../utils/useTraceUpdate';
import { useMagicDialog } from '../../MagicDialogContext';
import { showErrorSnackBar } from '../../SnackBar';
import { ChartJsBoxTemperatureChart } from './ChartJsBoxTemperatureChart';
import { ChartJsMmChart } from './ChartJsMmChart';
import { ChartJsMoistureChart } from './ChartJsMoistureChart';
import { ChartJsSalinityChart } from './ChartJsSalinityChart';
import { ChartJsTemperatureChart } from './ChartJsTemperatureChart';
import ChartTitleBar from './ChartTitleBar';
import type { ChartZoom, DomainBounds } from './TimeSeriesChart';

const SwitchLabel = ({ pawInfo }: { pawInfo: string }) => (
  <TooltipIcon secondaryText={pawInfo}>Translate to PAW</TooltipIcon>
);

const useStyles = makeStyles(() => {
  const chartWrapperStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  } as const;
  return {
    chartWrapper: chartWrapperStyle,
    chartLoaderWrapper: {
      ...chartWrapperStyle,
      height: 300,
    },
    noDataContainer: {
      ...chartWrapperStyle,
      height: '100%',
      padding: 10,
    },
  };
});

type Props = {
  siteDetails: SiteDetails;
  titleFontColor: 'textPrimary' | 'textSecondary';
  scaleLoading: boolean;
  scale: IMinMax | undefined;
  onSettingsClick: ((sensorId: string) => void) | undefined;
  displaySalinity: boolean;
  displayTemperature: boolean;
  displayAirTemperature: boolean;
  displayPrecipitation: boolean;
};

export function useChartZoom(data: readonly TransformedData[]): [ChartZoom, ChartZoom] {
  const [bounds, setBounds] = useState<DomainBounds>();
  useEffect(() => setBounds(undefined), [data]);
  return [
    useMemo(() => ({ allowed: true, bounds, setBounds }), [bounds, setBounds]),
    useMemo(() => ({ allowed: false, bounds, setBounds }), [bounds, setBounds]),
  ];
}

export const ZoomableChartGroup: FC<Props> = observer((props) => {
  const {
    siteDetails,
    titleFontColor,
    scaleLoading,
    scale,
    onSettingsClick,
    displaySalinity,
    displayTemperature,
    displayAirTemperature,
    displayPrecipitation,
    children,
  } = props;
  useTraceUpdate(props, 'Chart');
  const classes = useStyles();
  const intl = useIntl();
  const isAdmin = useUserStore().currentUser?.isAdmin;
  const showMmChart = process.env.REACT_APP_SHOW_MM_CHART == 'true';

  const calibrated = hasCalibration(siteDetails.configuration);
  const [translateToPAW, setTranslateToPAW] = useState(calibrated);
  useEffect(() => {
    if (!calibrated) {
      setTranslateToPAW(false);
    }
  }, [calibrated]);

  const currentUser = useCurrentUser();
  const farmStore = useFarmStore();

  const downloadCsv = useCallback(() => {
    try {
      const siteName =
        siteDetails.site.name.replace(/\s+/g, '-') ?? customerVisibleDeviceId(siteDetails.deviceIds);
      const startDateString = farmStore.selectedDates.startDate.format('YYYY-MM-DD');
      const endDateString = farmStore.selectedDates.endDate.format('YYYY-MM-DD');
      const fileName = `${siteName}_${startDateString}_${endDateString}.csv`;
      const data = siteDetails.toCsv({
        includeSalinity: displaySalinity,
        includeSoilTemperature: displayTemperature,
        includeAirTemperature: displayAirTemperature,
      });
      saveAs(new Blob([data], { type: 'text/csv;charset=utf-8' }), fileName);
    } catch (err) {
      showErrorSnackBar(getErrorMessageIntl(err, intl));
    }
  }, [
    displayAirTemperature,
    displaySalinity,
    displayTemperature,
    farmStore.selectedDates.endDate,
    farmStore.selectedDates.startDate,
    siteDetails,
    intl,
  ]);

  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const switchLabel = useMemo(() => {
    const pawInfo = calibrated ? 'Plant Available Water' : 'No soil parameters set for this sensor';
    return <SwitchLabel pawInfo={pawInfo} />;
  }, [calibrated]);
  const switchVisible = currentUser?.pawSwitchAvailable ?? false;
  const switchDisabled = hasCalibration(siteDetails.configuration) ? false : true;
  const switchOnChange = useCallback(() => setTranslateToPAW((prev) => !prev), [setTranslateToPAW]);

  const settingsVisible = onSettingsClick && currentUser?.hasSensorCalibrationAccess;

  const displayMidDepth = siteDetails.configuration.cableMiddle != undefined;

  const loading = siteDetails.loading || scaleLoading;
  const error = formatWithFallback(intl, siteDetails.errorObject, siteDetails.error, siteDetails.error);
  // const error = siteDetails.error;
  const sensorData = siteDetails.transformed.status == 'ready' ? siteDetails.transformed.value : undefined;
  const containsSalinity =
    siteDetails.transformed.status == 'ready' && siteDetails.transformed.value.containsSalinity;
  const containsBoxTemperature =
    siteDetails.transformed.status == 'ready' && siteDetails.transformed.value.containsBoxTemperature;

  const yLabel = translateToPAW
    ? intl.formatMessage({ id: 'plant_available_water' })
    : intl.formatMessage({ id: 'volumetric_water_content' });
  const minMax = scale ?? sensorData?.minMax;
  const min =
    translateToPAW && (minMax?.paw.min != null || siteDetails.site.safeRanges.plantAvailableWater?.[0] != null)
      ? Math.min(minMax?.paw.min ?? 999, siteDetails.site.safeRanges.plantAvailableWater?.[0] ?? 999)
      : minMax?.vwc.min;
  const max = minMax
    ? translateToPAW
      ? scale
        ? minMax.paw.max
        : Math.min(minMax.paw.max, 150)
      : minMax.vwc.max
    : undefined;

  const [masterChartZoom, followerChartZoom] = useChartZoom(sensorData?.data ?? []);

  const ChartErrorPlaceholder = (message: string) => (
    <div className={classes.chartLoaderWrapper}>
      <ChartTitleBar siteInfo={siteDetails} fontColor={titleFontColor} settingsVisible={settingsVisible} />
      <div className={classes.noDataContainer}>{message}</div>
    </div>
  );

  let chartInactive = false;

  const useSingleChart = siteDetails.configuration.placement == 'vertical';

  const { openMagicDialog } = useMagicDialog();

  const handleMagicClick = useCallback(() => {
    if (siteDetails.site.id) {
      openMagicDialog(siteDetails.site.id);
    }
  }, [siteDetails.site.id, openMagicDialog]);

  if (loading) {
    if (sensorData != undefined) {
      chartInactive = true;
    } else {
      return (
        <div className={classes.chartLoaderWrapper}>
          <CircularProgress color='secondary' style={{ margin: '0 auto' }} />
        </div>
      );
    }
  }

  if (sensorData == undefined || error) {
    return ChartErrorPlaceholder(error ?? 'No data available');
  }

  return (
    <div
      className={classes.chartWrapper}
      style={{
        opacity: chartInactive ? 0.5 : 1,
        pointerEvents: chartInactive ? 'none' : 'initial',
        userSelect: 'none',
      }}
    >
      <ChartTitleBar
        siteInfo={siteDetails}
        isSmall={isSmall}
        fontColor={titleFontColor}
        switchProps={{
          checked: translateToPAW,
          label: switchLabel,
          onChange: switchOnChange,
          disabled: switchDisabled,
          visible: switchVisible,
        }}
        settingsVisible={settingsVisible}
        onSettingsClick={onSettingsClick}
      >
        <IconButton disabled={siteDetails.loading} onClick={downloadCsv} size='large'>
          <DownloadIcon />
        </IconButton>
        {isAdmin && (
          <IconButton disabled={siteDetails.loading} onClick={handleMagicClick} size='large'>
            <AutoFixHighIcon />
          </IconButton>
        )}
        {children}
      </ChartTitleBar>
      {sensorData.containsMoisture ? (
        <ChartJsMoistureChart
          sensorChartData={sensorData}
          safeRanges={siteDetails.site.safeRanges}
          displayMidDepth={displayMidDepth}
          nameMap={siteDetails.nameMap}
          coordinates={siteDetails.site.coordinates}
          showPlantAvailableWater={translateToPAW}
          displayPrecipitation={displayPrecipitation}
          yLabel={yLabel}
          yMax={max}
          yMin={min}
          chartZoom={masterChartZoom}
          useSingleChart={useSingleChart}
        />
      ) : (
        <Box height='100px' display='flex' alignItems='center'>
          No moisture data available
        </Box>
      )}
      {isAdmin && showMmChart && (
        <ChartJsMmChart
          mmChartData={sensorData}
          chartZoom={masterChartZoom}
          nameMap={siteDetails.nameMap}
          coordinates={siteDetails.site.coordinates}
        />
      )}
      {containsSalinity && displaySalinity && (
        <ChartJsSalinityChart
          data={sensorData.data}
          chartZoom={followerChartZoom}
          nameMap={siteDetails.nameMap}
          useSingleChart={useSingleChart}
          coordinates={siteDetails.site.coordinates}
        />
      )}
      {displayTemperature && (
        <ChartJsTemperatureChart
          data={sensorData.data}
          chartZoom={followerChartZoom}
          displayMidDepth={displayMidDepth}
          nameMap={siteDetails.nameMap}
          useSingleChart={useSingleChart}
          coordinates={siteDetails.site.coordinates}
        />
      )}
      {displayAirTemperature && containsBoxTemperature && (
        <ChartJsBoxTemperatureChart
          data={sensorData.data}
          chartZoom={followerChartZoom}
          coordinates={siteDetails.site.coordinates}
        />
      )}
    </div>
  );
});
