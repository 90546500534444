import { Agriculture, ArrowBack, Refresh, WaterTwoTone as WaterDrop } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import type { RequiredActionFunctionToolCall } from 'openai/resources/beta/threads/runs/runs.mjs';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { FarmStore } from '../../../dataHandlers/FarmStore';
import { useFarmStore } from '../../../dataHandlers/RootStore';
import theme from '../../../theme';
import { BASE_URL } from '../../../utils/consts';
import { getWeatherForecastLatLon } from '../../../utils/getWeather';
import Chat from '../Chat';
import { Message, Messages } from '../Message';
import { useFetchWithAuth } from '../utils/fetchWithAuth';
import { useSoilMoistureData } from './useSoilMoistureData';

interface GlobalMagicDialogContextType {
  openMagicDialog: (siteId?: string) => void;
  setSelectedSite: (siteId: string) => void;
}

const GlobalMagicDialogContext = createContext<GlobalMagicDialogContextType | undefined>(undefined);

export const GlobalMagicDialogProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [selectedSiteId, setSelectedSiteId] = useState<string>('');
  const [chatKey, setChatKey] = useState(0);
  const farmStore = useFarmStore();
  const [cropType, setCropType] = useState<string | null>(null);
  const [depths, setDepths] = useState<{ topDepth: number; bottomDepth: number } | null>(null);
  const [generalChat, setGeneralChat] = useState<boolean | null>(null);
  const [threads, setThreads] = useState<Array<{ threadId: string; userId: string; createdAt: Date }>>([]);
  const [selectedHistoricThreadId, setSelectedHistoricThreadId] = useState<string | null>(null);
  const [loadingHistoricThread, setLoadingHistoricThread] = useState(false);
  const [historicThreadMessages, setHistoricThreadMessages] = useState<
    Array<{
      role: string;
      content: Array<{
        type: string;
        text: {
          value: string;
        };
      }>;
    }>
  >([]);
  const fetchWithAuth = useFetchWithAuth();
  console.log({ selectedSiteId });

  const selectedSite = useMemo(() => {
    if (!selectedSiteId) return undefined;

    // Find site in field sites
    const fields = farmStore.areas.filter((area) => area.kind === 'field');
    const fieldSite = fields
      .flatMap((field) => field.fieldDetails.siteDetails)
      .find((site) => site.site.id === selectedSiteId);
    if (fieldSite) {
      return {
        kind: 'site' as const,
        siteDetails: fieldSite,
      };
    }

    // Find standalone site
    const site = farmStore.areas.find(
      (area) => area.kind === 'site' && area.siteDetails.site.id === selectedSiteId
    );
    if (site?.kind === 'site') {
      return site;
    }

    return undefined;
  }, [farmStore.areas, selectedSiteId]);
  console.log('selectedSite', { selectedSite });

  useEffect(() => {
    if (selectedHistoricThreadId) {
      setLoadingHistoricThread(true);
      const fetchHistoricThread = async () => {
        const response = await fetchWithAuth(`${BASE_URL}/llm/threads/${selectedHistoricThreadId}/messages`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const respJson = await response.json();
        const messages = respJson.data as {
          role: string;
          content: Array<{
            type: string;
            text: {
              value: string;
            };
          }>;
        }[];
        console.log('messages', { messages });
        setHistoricThreadMessages(messages.reverse());
        setLoadingHistoricThread(false);
      };
      fetchHistoricThread();
    }
  }, [selectedHistoricThreadId, fetchWithAuth]);

  useEffect(() => {
    if (selectedSite) {
      setCropType(selectedSite.siteDetails.site.cropType ?? '');
      setDepths({
        topDepth: selectedSite.siteDetails.configuration.cableTop.depth,
        bottomDepth: selectedSite.siteDetails.configuration.cableBottom.depth,
      });
    }
  }, [selectedSite]);

  const fetchAllThreads = useCallback(async () => {
    const response = await fetchWithAuth(`${BASE_URL}/llm/threads`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const threads = await response.json();
    console.log('threads', { threads });
    setThreads(threads as Array<{ threadId: string; userId: string; createdAt: Date }>);
  }, [fetchWithAuth]);

  useEffect(() => {
    fetchAllThreads();
  }, [fetchAllThreads]);

  const openMagicDialog = useCallback((siteId?: string) => {
    console.log('openMagicDialog', { siteId });
    if (siteId) {
      setSelectedSiteId(siteId);
    }
    setOpen(true);
    setChatKey((prev) => prev + 1);
  }, []);

  const setSelectedSite = useCallback((siteId: string) => {
    setSelectedSiteId(siteId);
    setChatKey((prev) => prev + 1);
  }, []);

  const handleClose = () => {
    setOpen(false);
    setSelectedSiteId('');
    setCropType(null);
    setDepths(null);
    setGeneralChat(null);
    setChatKey((prev) => prev + 1);
    setSelectedHistoricThreadId(null);
    setLoadingHistoricThread(false);
    setHistoricThreadMessages([]);
  };

  const { processSoilMoistureData } = useSoilMoistureData(selectedSiteId);

  const functionCallHandler = useCallback(
    async (call: RequiredActionFunctionToolCall) => {
      console.log('functionCallHandler', { call });
      if (call?.function?.name === 'get_weather_forecast') {
        const args = JSON.parse(call.function.arguments as string);
        if (!selectedSite) return JSON.stringify({});

        const data = await getWeatherForecastLatLon(
          selectedSite.siteDetails.site.coordinates.lat,
          selectedSite.siteDetails.site.coordinates.lng
        );
        return JSON.stringify(data);
      }
      if (call?.function?.name === 'get_soil_moisture_data') {
        console.log('CALLED get_soil_moisture_data');
        // farmStore.setSelectedDates(moment().subtract(7, 'days').startOf('day'), moment().endOf('day'));
        const dailyData = await processSoilMoistureData();
        return JSON.stringify(dailyData);
      }
      return Promise.resolve('');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedSite]
  );

  useEffect(() => {
    if (open) {
      const fetchAllThreads = async () => {
        const threads = await fetch(`${BASE_URL}/llm/threads`);
        console.log('threads', { threads });
        // setThreads(threads);
      };
      fetchAllThreads();
    }
  }, [open]);

  return (
    <GlobalMagicDialogContext.Provider value={{ openMagicDialog, setSelectedSite }}>
      {children}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='md'
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: { xs: 'calc(100% - 8px)', sm: 'calc(100% - 24px)', md: theme.breakpoints.values.md + 250 },
            maxHeight: { xs: 'calc(100% - 16px)', sm: 'calc(100% - 48px)' },
            margin: { xs: '8px', sm: '24px' },
            padding: { xs: '2px', sm: '16px' },
            width: { xs: '100%', sm: 'calc(100% - 48px)' },
          },
        }}
      >
        {generalChat === true && (
          <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Button
              onClick={() => {
                setGeneralChat(null);
                setSelectedSiteId('');
              }}
              variant='text'
              sx={{ minWidth: 'auto', p: 1 }}
            >
              <ArrowBack />
            </Button>
            General Agricultural Advisor
          </DialogTitle>
        )}
        {generalChat === false && (
          <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Button
              onClick={() => {
                setGeneralChat(null);
                setSelectedSiteId('');
              }}
              variant='text'
              sx={{ minWidth: 'auto', p: 1 }}
            >
              <ArrowBack />
            </Button>
            Irrigation Recommendation{' '}
            {`${selectedSiteId ? `for ${selectedSite?.siteDetails.site.name} (${cropType})` : ''}`}
          </DialogTitle>
        )}
        <DialogContent
          sx={{
            height: '600px',
            display: 'flex',
            flexDirection: 'row',
            padding: { xs: '4px', sm: '8px' },
            gap: 2,
          }}
        >
          <List
            sx={{
              width: '250px',
              borderRight: 1,
              borderColor: 'divider',
              overflowY: 'auto',
            }}
          >
            <ListItem
              secondaryAction={
                <Button onClick={() => fetchAllThreads()} size='small'>
                  <Refresh />
                </Button>
              }
            >
              <ListItemText primary='Chat History' sx={{ textAlign: 'center' }} />
            </ListItem>
            <Divider />
            {threads.map((thread) => (
              <ListItemButton
                key={thread.threadId}
                selected={selectedHistoricThreadId === thread.threadId}
                onClick={() => setSelectedHistoricThreadId(thread.threadId)}
              >
                <ListItemText
                  primary={'Chat ' + new Date(thread.createdAt).toLocaleDateString()}
                  secondary={new Date(thread.createdAt).toLocaleTimeString()}
                />
              </ListItemButton>
            ))}
          </List>

          {!selectedHistoricThreadId ? (
            <ChatContent
              generalChat={generalChat}
              chatKey={chatKey}
              functionCallHandler={functionCallHandler}
              cropType={cropType}
              selectedSiteId={selectedSiteId}
              setSelectedSiteId={setSelectedSiteId}
              setCropType={setCropType}
              setGeneralChat={setGeneralChat}
              farmStore={farmStore}
            />
          ) : loadingHistoricThread ? (
            <div>Loading...</div>
          ) : (
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <Messages>
                {historicThreadMessages.map((msg, index) => (
                  <Message
                    key={index}
                    role={msg.role as 'user' | 'assistant' | 'code'}
                    text={msg.content.map((content) => content.text.value).join('')}
                  />
                ))}
              </Messages>
            </div>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            padding: { xs: '4px', sm: '8px' },
          }}
        >
          {selectedHistoricThreadId ? (
            <Button
              onClick={() => {
                setSelectedHistoricThreadId(null);
                setHistoricThreadMessages([]);
                setLoadingHistoricThread(false);
                setGeneralChat(null);
                setSelectedSiteId('');
                setCropType(null);
                setDepths(null);
              }}
            >
              Close chat history
            </Button>
          ) : (
            <Button onClick={handleClose}>Close</Button>
          )}
        </DialogActions>
      </Dialog>
    </GlobalMagicDialogContext.Provider>
  );
};

const ChatContent = ({
  generalChat,
  chatKey,
  functionCallHandler,
  cropType,
  selectedSiteId,
  setSelectedSiteId,
  setCropType,
  setGeneralChat,
  farmStore,
}: {
  generalChat: boolean | null;
  chatKey: number;
  functionCallHandler: (call: RequiredActionFunctionToolCall) => Promise<string>;
  cropType: string | null;
  selectedSiteId: string;
  setSelectedSiteId: (siteId: string) => void;
  setCropType: (cropType: string) => void;
  setGeneralChat: (generalChat: boolean | null) => void;
  farmStore: FarmStore;
}) => {
  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      {generalChat !== null &&
        (generalChat === true ? (
          <Chat
            key={chatKey}
            functionCallHandler={functionCallHandler}
            initialMessage={`Please summarize my soil moisture data. If needed, accomplish this by performing 3 functions calls: get soil moisture data, get weather forecast. Before fetching the weather forecast and soil moisture data, search your knowledge base (provided documents) for relevant information. If possible draw some concise conclusions that I can use to make decisions. ${
              cropType !== '-'
                ? `Find information relevant to growing ${cropType}. Prioritize Plant Available Water (PAW) as the source of moisture information.`
                : ''
            }`}
            generalChat={generalChat}
          />
        ) : selectedSiteId ? (
          <Chat
            key={chatKey}
            functionCallHandler={functionCallHandler}
            initialMessage={`Please summarize my soil moisture data. If needed, accomplish this by performing 3 functions calls: get soil moisture data, get weather forecast. Before fetching the weather forecast and soil moisture data, search your knowledge base (provided documents) for relevant information. If possible draw some concise conclusions that I can use to make decisions. ${
              cropType !== '-'
                ? `Find information relevant to growing ${cropType}. Prioritize Plant Available Water (PAW) as the source of moisture information.`
                : ''
            }`}
            generalChat={generalChat}
          />
        ) : null)}
      {generalChat !== true && selectedSiteId === '' && (
        <div
          style={{
            display: 'flex',
            gap: '16px',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            flexWrap: 'wrap',
          }}
        >
          {generalChat === false ? (
            // Show site selection buttons
            farmStore.areas
              .flatMap((area) =>
                area.kind === 'field'
                  ? area.fieldDetails.siteDetails
                  : area.kind === 'site'
                  ? [area.siteDetails]
                  : []
              )
              .map((siteDetails) => (
                <Button
                  key={siteDetails.site.id}
                  variant='contained'
                  size='large'
                  onClick={() => {
                    setSelectedSiteId(siteDetails.site.id);
                    setCropType(siteDetails.site.cropType ?? '');
                  }}
                  sx={{
                    padding: '24px',
                    fontSize: '1.2rem',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                  }}
                >
                  <WaterDrop sx={{ fontSize: '2.5rem' }} />
                  {siteDetails.site.name}
                </Button>
              ))
          ) : (
            // Show initial choice buttons
            <>
              <Button
                variant='contained'
                size='large'
                onClick={() => setGeneralChat(false)}
                sx={{
                  padding: '24px',
                  fontSize: '1.2rem',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                }}
              >
                <WaterDrop sx={{ fontSize: '2.5rem' }} />
                Irrigation Recommendation
              </Button>
              <Button
                variant='contained'
                size='large'
                onClick={() => setGeneralChat(true)}
                sx={{
                  padding: '24px',
                  fontSize: '1.2rem',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                }}
              >
                <Agriculture sx={{ fontSize: '2.5rem' }} />
                General Agricultural Advisor
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export const useGlobalMagicDialog = () => {
  const context = useContext(GlobalMagicDialogContext);
  if (!context) {
    throw new Error('useGlobalMagicDialog must be used within a GlobalMagicDialogProvider');
  }
  return context;
};
