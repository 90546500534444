import moment from 'moment';
import { useCallback } from 'react';
import { useFarmStore } from '../../../dataHandlers/RootStore';

const isNumber = (value: any): value is number => typeof value === 'number';

export const useSoilMoistureData = (selectedSiteId: string) => {
  const farmStore = useFarmStore();

  const getData = useCallback(async () => {
    const site = farmStore.areas
      .filter((area) => area.kind === 'site')
      .find((area) => area.siteDetails.site.id === selectedSiteId);

    if (site) {
      const details = site.siteDetails;
      if (details.transformed.status === 'ready') {
        return details.transformed.value;
      }
    }

    const field = farmStore.areas
      .filter((area) => area.kind === 'field')
      .find((area) => area.fieldDetails.siteDetails.some((site) => site.site.id === selectedSiteId));

    if (field) {
      const details = field.fieldDetails.siteDetails.find((site) => site.site.id === selectedSiteId);
      if (details?.transformed.status === 'ready') {
        return details.transformed.value;
      }
    }

    return undefined;
  }, [farmStore.areas, selectedSiteId]);

  const getDepthsAndCropInfo = useCallback(async () => {
    let details;
    const site = farmStore.areas
      .filter((area) => area.kind === 'site')
      .find((area) => area.siteDetails.site.id === selectedSiteId);

    if (!site) {
      const field = farmStore.areas
        .filter((area) => area.kind === 'field')
        .find((area) => area.fieldDetails.siteDetails.some((site) => site.site.id === selectedSiteId));
      if (!field) return { topDepth: undefined, bottomDepth: undefined, crop: undefined };
      details = field.fieldDetails.siteDetails.find((site) => site.site.id === selectedSiteId);
    } else {
      details = site.siteDetails;
    }

    if (!details) {
      return {
        topDepth: undefined,
        bottomDepth: undefined,
        crop: undefined,
        safeRangeHigh: undefined,
        safeRangeLow: undefined,
      };
    }

    return {
      topDepth: details.configuration.cableTop.depth,
      bottomDepth: details.configuration.cableBottom.depth,
      crop: details.site.cropType,
      safeRangeHigh: details.site.safeRanges.plantAvailableWater?.[1],
      safeRangeLow: details.site.safeRanges.plantAvailableWater?.[0],
    };
  }, [farmStore.areas, selectedSiteId]);

  const processSoilMoistureData = useCallback(async () => {
    await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (
          farmStore.areas
            .filter((area) => area.kind == 'site')
            .every((area) => area.siteDetails.transformed.status == 'ready')
        ) {
          clearInterval(interval);
          resolve(true);
        }
        if (
          farmStore.areas
            .filter((area) => area.kind == 'site')
            .some((area) => area.siteDetails.transformed.status == 'error')
        ) {
          clearInterval(interval);
          resolve(true);
        }
      }, 500);
    });

    const data = await getData();
    if (!data?.data) return [];

    const { topDepth, bottomDepth, crop, safeRangeHigh, safeRangeLow } = await getDepthsAndCropInfo();

    // ... rest of the data processing logic ...
    // [Keep the existing dailyStats and dailyData processing logic here]
    // Group data by day
    const dailyStats = data.data.reduce(
      (
        acc: Record<
          string,
          {
            pawTop: { min: number | '-'; max: number | '-'; sum: number | '-'; count: number };
            pawBot: { min: number | '-'; max: number | '-'; sum: number | '-'; count: number };
            vwcTop: { min: number | '-'; max: number | '-'; sum: number | '-'; count: number };
            vwcBot: { min: number | '-'; max: number | '-'; sum: number | '-'; count: number };
          }
        >,
        item
      ) => {
        const day = moment(item.timestamp).format('YYYY-MM-DD');
        if (!item.pawBot && !item.pawTop && !item.vwcBot && !item.vwcTop) return acc;

        if (!acc[day]) {
          acc[day] = {
            pawTop: {
              min: item.pawTop ?? '-',
              max: item.pawTop ?? '-',
              sum: item.pawTop ?? '-',
              count: 1,
            },
            pawBot: {
              min: item.pawBot ?? '-',
              max: item.pawBot ?? '-',
              sum: item.pawBot ?? '-',
              count: 1,
            },
            vwcTop: {
              min: item.vwcTop ?? '-',
              max: item.vwcTop ?? '-',
              sum: item.vwcTop ?? '-',
              count: 1,
            },
            vwcBot: {
              min: item.vwcBot ?? '-',
              max: item.vwcBot ?? '-',
              sum: item.vwcBot ?? '-',
              count: 1,
            },
          };
        } else {
          acc[day] = {
            pawTop: {
              min:
                isNumber(acc[day].pawTop.min) && isNumber(item.pawTop ?? '-')
                  ? Math.min(acc[day].pawTop.min, item.pawTop!)
                  : acc[day].pawTop.min,
              max:
                isNumber(acc[day].pawTop.max) && isNumber(item.pawTop ?? '-')
                  ? Math.max(acc[day].pawTop.max, item.pawTop!)
                  : acc[day].pawTop.max,
              sum:
                isNumber(acc[day].pawTop.sum) && isNumber(item.pawTop ?? '-')
                  ? acc[day].pawTop.sum + item.pawTop!
                  : acc[day].pawTop.sum,
              count: acc[day].pawTop.count + 1,
            },
            pawBot: {
              min:
                isNumber(acc[day].pawBot.min) && isNumber(item.pawBot ?? '-')
                  ? Math.min(acc[day].pawBot.min, item.pawBot!)
                  : acc[day].pawBot.min,
              max:
                isNumber(acc[day].pawBot.max) && isNumber(item.pawBot ?? '-')
                  ? Math.max(acc[day].pawBot.max, item.pawBot!)
                  : acc[day].pawBot.max,
              sum:
                isNumber(acc[day].pawBot.sum) && isNumber(item.pawBot ?? '-')
                  ? acc[day].pawBot.sum + item.pawBot!
                  : acc[day].pawBot.sum,
              count: acc[day].pawBot.count + 1,
            },
            vwcTop: {
              min:
                isNumber(acc[day].vwcTop.min) && isNumber(item.vwcTop ?? '-')
                  ? Math.min(acc[day].vwcTop.min, item.vwcTop!)
                  : acc[day].vwcTop.min,
              max:
                isNumber(acc[day].vwcTop.max) && isNumber(item.vwcTop ?? '-')
                  ? Math.max(acc[day].vwcTop.max, item.vwcTop!)
                  : acc[day].vwcTop.max,
              sum:
                isNumber(acc[day].vwcTop.sum) && isNumber(item.vwcTop ?? '-')
                  ? acc[day].vwcTop.sum + item.vwcTop!
                  : acc[day].vwcTop.sum,
              count: acc[day].vwcTop.count + 1,
            },
            vwcBot: {
              min:
                isNumber(acc[day].vwcBot.min) && isNumber(item.vwcBot ?? '-')
                  ? Math.min(acc[day].vwcBot.min, item.vwcBot!)
                  : acc[day].vwcBot.min,
              max:
                isNumber(acc[day].vwcBot.max) && isNumber(item.vwcBot ?? '-')
                  ? Math.max(acc[day].vwcBot.max, item.vwcBot!)
                  : acc[day].vwcBot.max,
              sum:
                isNumber(acc[day].vwcBot.sum) && isNumber(item.vwcBot ?? '-')
                  ? acc[day].vwcBot.sum + item.vwcBot!
                  : acc[day].vwcBot.sum,
              count: acc[day].vwcBot.count + 1,
            },
          };
        }
        return acc;
      },
      {}
    );

    const dailyData = Object.entries(dailyStats).map(([date, stats]) => ({
      date,
      crop: `I am growing ${crop}`,
      description: {
        pawTop: `Plant available water (% of field capacity) at depth ${
          topDepth ? `${topDepth}cm` : 'surface soil'
        } ('-' if not available)`,
        pawBot: `Plant available water (% of field capacity) at depth ${
          bottomDepth ? `${bottomDepth}cm` : 'deep soil'
        } ('-' if not available)`,
        vwcTop: `Volumetric water content (%) at depth ${
          topDepth ? `${topDepth}cm` : 'surface soil'
        } ('-' if not available)`,
        vwcBot: `Volumetric water content (%) at depth ${
          bottomDepth ? `${bottomDepth}cm` : 'low soil'
        } ('-' if not available)`,
        pawSafeRangeHigh: `Plant available water safe range high (${safeRangeHigh}%)`,
        pawSafeRangeLow: `Plant available water safe range low (${safeRangeLow}%)`,
      },
      pawTop: {
        min: stats.pawTop.min,
        max: stats.pawTop.max,
        avg:
          isNumber(stats.pawTop.sum) && isNumber(stats.pawTop.count) ? stats.pawTop.sum / stats.pawTop.count : '-',
      },
      pawBot: {
        min: stats.pawBot.min,
        max: stats.pawBot.max,
        avg:
          isNumber(stats.pawBot.sum) && isNumber(stats.pawBot.count) ? stats.pawBot.sum / stats.pawBot.count : '-',
      },
      vwcTop: {
        min: stats.vwcTop.min,
        max: stats.vwcTop.max,
        avg:
          isNumber(stats.vwcTop.sum) && isNumber(stats.vwcTop.count) ? stats.vwcTop.sum / stats.vwcTop.count : '-',
      },
      vwcBot: {
        min: stats.vwcBot.min,
        max: stats.vwcBot.max,
        avg:
          isNumber(stats.vwcBot.sum) && isNumber(stats.vwcBot.count) ? stats.vwcBot.sum / stats.vwcBot.count : '-',
      },
    }));

    return dailyData;
  }, [farmStore.areas, getData, getDepthsAndCropInfo]);

  return { processSoilMoistureData };
};
